import React from "react";
import {Row, Col} from "react-bootstrap";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SubBanner from "../Common/SubBanner";

class About extends React.Component {
    constructor(props) {
        super(props);
        document.title = this.props.pageTitle
    }

    render() {
        return (
            <>
                <Header/>
                <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

                <div className="content">
                    <section className="sec-100px about">
                        <div className="container">
                            <Row className={"d-flex flex-wrap"}>
                                <Col md={6} className={"mb-3"}>
                                    <h4>Location and Administration</h4>
                                    <hr/>
                                    <p>
                                        Mokhasan Village Gram Panchayath name is Mokhasan. Mokhasan is 8 km distance from Sub District HeadQuarter Kalol and it
                                        is 25 km distance from District HeadQuarter Gandhinagar. Nearest Statutory Town is Kalol in 8 km Distance . Mokhasan
                                        Total area is 398.57 hectares, Forest area is 6.97 hectares, Non-Agricultural area is 8.14 hectares,Total irrigated area
                                        is 398.57 hectares andTotal Water fall area is 0 hectares
                                    </p>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <img src="/assets/images/about_images/location.jpg" alt="Health" className={"w-100 h-auto rounded-circle"}/>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <img src="/assets/images/about_images/Education.jpg" alt="Health" className={"w-100 h-auto rounded-circle"}/>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <h4>Education</h4>
                                    <hr/>
                                    <p>
                                        Govt Primary and Govt Secondary Schools are available in this Village. Nearest Private Medical College, Private MBA
                                        college and Govt Polytechnic College are in Gandhinagar. Nearest Private Disabled School, Private Pre Primary School,
                                        Govt Senior Secondary School, Govt Arts and Science Degree College, Private Engineering College and Govt ITA College are
                                        in Kalol.
                                    </p>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <h4>Health</h4>
                                    <hr/>
                                    <p>
                                        1 Primary Health care centre , 1 Primary Health Sub-Centre , 1 TB Clinic , 1 Veterinary Hospital are available in this
                                        village.
                                    </p>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <img src="/assets/images/about_images/health.jpg" alt="Health" className={"w-100 h-auto rounded-circle"}/>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <img src="/assets/images/about_images/Agriculture.jpg" alt="Health" className={"w-100 h-auto rounded-circle"}/>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <h4>Agriculture</h4>
                                    <hr/>
                                    <p>
                                        Pearlmillet/bajra, Wheat and Castor are agriculture commodities grow in this village. 8 hours agricultural power supply
                                        in summer and 8 hours agricultural power supply in winter is available in this village. Total irrigated area in this
                                        village is 398.57 hectares from Boreholes/Tube wells 398.57 hectares is the Source of irrigation.
                                    </p>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <h4>Drinking-Water and Sanitation</h4>
                                    <hr/>
                                    <p>
                                        Treated Tap Water Supply all round the year and in summer also available. Covered Well and Tube Wells/Boreholes are
                                        other Drinking Water sources.
                                    </p>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <img src="/assets/images/about_images/Drinking-Water.jpg" alt="Health" className={"w-100 h-auto rounded-circle"}/>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <img src="/assets/images/about_images/Communication.jpg" alt="Health" className={"w-100 h-auto rounded-circle"}/>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <h4>Communication</h4>
                                    <hr/>
                                    <p>
                                        Sub Post Office is available in this Village. LandLine available. Mobile Coverage is available. Internet Centre
                                        available in this village. Nearest Private Courier Facility is in 5 - 10 km.
                                    </p>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <h4>Transportation</h4>
                                    <hr/>
                                    <p>
                                        Public Bus service available in this village. Nearest Railway Station is in 5 - 10 km. Animal Driven Carts are there in
                                        this Village.
                                    </p>
                                    <p>
                                        Nearest National Highway is in 5 - 10 km. Nearest State Highway is in 5 - 10 km. Nearest District Road is in less than 5
                                        km.
                                    </p>
                                    <p>
                                        Pucca road, Macadam Road and Foot Path are other Roads and Transportation within the village.
                                    </p>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <img src="/assets/images/about_images/Transportation.jpg" alt="Health" className={"w-100 h-auto rounded-circle"}/>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <img src="/assets/images/about_images/Commerce.jpg" alt="Health" className={"w-100 h-auto rounded-circle"}/>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <h4>Commerce</h4>
                                    <hr/>
                                    <p>
                                        Nearest ATM is in 5 - 10 km. Commercial Bank available in this village. Nearest Cooperative Bank is in 5 - 10 km.
                                    </p>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <h4>Other Amenities</h4>
                                    <hr/>
                                    <p>
                                        This Village has a Power supply with 24 hour power supply in summer and 24 hour power supply in winter, Anganwadi
                                        centre, ASHA, Birth & Death registration office, Public library, Daily News Paper and Polling station are the other
                                        amenities in the village.
                                    </p>
                                </Col>
                                <Col md={6} className={"mb-3"}>
                                    <img src="/assets/images/about_images/health.jpg" alt="Health" className={"w-100 h-auto rounded-circle"}/>
                                </Col>
                            </Row>
                        </div>
                    </section>
                </div>
                <Footer/>
            </>
        )
    }
}

export default About;