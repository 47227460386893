import React from "react";
import {Button, Card, Col, ListGroup, Row} from "react-bootstrap";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";
import {Link} from "react-router-dom";
import {GetData} from "../api_key/GetData";
import moment from "moment";

class Events extends React.Component {
    constructor(props) {
        super(props);
        this.state = {eventsDataItems: []};
        document.title = this.props.pageTitle
        this.loadAllEvents = this.loadAllEvents.bind(this)
    }

    componentDidMount() {
        this.loadAllEvents()
        window.scrollTo(0, 0);
    }
    loadAllEvents() {
        GetData("/front/get_events")
            .then(result => {
                this.setState({eventsData: result.response});
                const listItems = result.response.map((data, index) =>
                    <Row key={index}>
                        <Col md={12}>
                            <ListGroup as={"ul"}>
                                <ListGroup.Item as={"li"} className={"col-sm-5 no-padding eve-img bg-transparent border-0"}>
                                    <img className="img-responsive" src={data.banner_image_file} alt=""/>
                                    <div className="date">{moment(data.start_date_time).date()} <p>{moment(data.start_date_time).format('MMMM, ')}</p></div>
                                </ListGroup.Item>
                                <ListGroup.Item as={"li"} className={"col-sm-7 no-padding bg-transparent border-0"}>
                                    <div className="event-detail">
                                        <h4>{data.title}</h4>
                                        <span><em className="ion-ios-location-outline"></em>{data.location}</span>
                                        <span><em className="ion-ios-clock-outline"></em> {moment(data.start_date_time).format('MMMM Do')} To {moment(data.end_date_time).format('MMMM Do')} </span>
                                        <p>{data.description}</p>
                                        <Link to={"/register_your_interest?uuid="+data.uuid} className={"btn btn-primary d-inline-block w-100 w-md-50 mr-2"}>Register Your Interest</Link>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                );
                this.setState({eventsDataItems: listItems, dataLoader: false});
            })
    }
    render() {
        return (<>
                <Header/>
                <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

                <div className="content">
                    <div className="container">
                        <section className="sec-100px event event-page">
                            {this.state.eventsDataItems}

                            <h2 className="page-title">Near by Hotel Lists</h2>
                            <Row className={"d-flex align-self-center flex-wrap m-0"}>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>LA QUINTA by WYNDHAM (35 ROOMS),</span><br/>
                                            4615 CHAMBERS: RD <br/>
                                            MACON, GA 31206: <br/>
                                            PH: -478-788-6226 <br/>
                                            RATE - $ 100.00 + TAX
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant={"primary"} className={"mt-3"} onClick={() => {
                                                let anchorTag = document.createElement("a")
                                                anchorTag.href = "https://www.wyndhamhotels.com/laquinta/macon-georgia/la-quintamacon-west/roomsrates?&checkInDate=06/23/2023&checkOutDate=06/25/2023&groupCode=C GMK23"
                                                anchorTag.target = "_blank"
                                                anchorTag.click()
                                            }}>Book Now</Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>COUNTRY INN & SUITE (30 ROOMS),</span><br/>
                                            4717 CHAMBERS RD<br/>
                                            MACON, GA 31206<br/>
                                            PH:- 478-845-4083<br/>
                                            RATE - $ 109.00 + TAX<br/>
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant={"primary"} className={"mt-3"} onClick={() => {
                                                let anchorTag = document.createElement("a")
                                                anchorTag.href = "https://www.radissonhotelsamericas.com/en-us/booking/roomdisplay?hotelCode=USAMWGA&checkInDate=2023-06-23&checkOutDate=2023-06-25&adults%5B%5D=1&children%5B%5D=0&searchType=pac&promotionCode=mok23"
                                                anchorTag.target = "_blank"
                                                anchorTag.click()
                                            }}>Book Now</Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>RAMADA by WYNDHAM (50 ROOMS),</span><br/>
                                            4755 CHAMBERS RD,<br/>
                                            MACON, GA 31206,<br/>
                                            PH:- 478-787-6940,<br/>
                                            RATE - $ 69.00 + TAX
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>SUPER 8 by WYNDHAM (50 ROOMS),</span><br/>
                                            4765 CHAMBERS RD<br/>
                                            MACON, GA 31206<br/>
                                            PH:- 478-254-5290<br/>
                                            RATE - $ 65.00 + TAX
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>HOLIDAY INN EXPRESS & SUITES (40 ROOMS),</span><br/>
                                            4970 HARRISON RD,<br/>
                                            MACON, GA 31206,<br/>
                                            PH:-478-803-0033,<br/>
                                            RATE - $ 95.00 + TAX,
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>HAMPTON INN & SUITES ( 38 ROOMS ),</span><br/>
                                            5010 EISENHOWER PKWY, <br/>
                                            MACON, GA 31206, <br/>
                                            PH:- 478-803-4900, <br/>
                                            RATE - $ 154.00 + TAX ( STANDARD ROOM ), <br/>
                                            RATE - $ 174.00 + TAX ( SUITES ROOM )
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} className={"p-3"}>
                                    <Card className={"address-card-box"}>
                                        <Card.Body>
                                            <span style={{fontWeight: "bold"}}>COMFORT INN & SUITES (35 ROOMS ),</span><br/>
                                            5000 HARRISON RD, <br/>
                                            MACON, GA 31206, <br/>
                                            PH:- 478-238-9292, <br/>
                                            RATE - $ 129.00 + TAX
                                        </Card.Body>
                                        <Card.Footer>
                                            <Button variant={"primary"} className={"mt-3"} onClick={() => {
                                                let anchorTag = document.createElement("a")
                                                anchorTag.href = "https://www.choicehotels.com/reservations/groups/NG21E2"
                                                anchorTag.target = "_blank"
                                                anchorTag.click()
                                            }}>Book Now</Button>
                                        </Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                        </section>
                    </div>
                </div>

                <Footer/>
            </>
        )
    }
}

export default Events;