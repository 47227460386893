import React from "react";
import {Link} from "react-router-dom";
import {ListGroup} from "react-bootstrap";

class Footer extends React.Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <ListGroup as={"ul"} className="foot-link">
                        <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                            <Link to="/">Home</Link>
                        </ListGroup.Item>
                        <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                            <Link to="/events">Events</Link>
                        </ListGroup.Item>
                        <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                            <Link to="/gallery">Gallery</Link>
                        </ListGroup.Item>
                        <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                            <Link to="/about">About</Link>
                        </ListGroup.Item>
                        <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                            <Link to="/sponsors">Help</Link>
                        </ListGroup.Item>
                        <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                            <Link to="/contact">Contact</Link>
                        </ListGroup.Item>
                    </ListGroup>
                    <div className="foot-logo">
                        <img src="/assets/images/logo-footer.png" alt=""/>
                    </div>
                    <div className="under-footer">
                        <ListGroup as={"ul"} className="con-info">
                            <ListGroup.Item as={"li"} className={"bg-transparent border-0 p-0"}>
                                <p><em className="fa fa-map-marker"></em> Mokhasan-382740, Kalol, Gandhinagar, Gujarat</p>
                            </ListGroup.Item>
                            <ListGroup.Item as={"li"} className={"bg-transparent border-0 p-0"}>
                                <p><em className="fa fa-envelope"></em> mail@Mokhasan.com</p>
                            </ListGroup.Item>
                        </ListGroup>
                        <ListGroup as={"ul"} className="social-link">
                            <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                                <Link to="/*">Facebook</Link>
                            </ListGroup.Item>
                            <ListGroup.Item as={"li"} className={"bg-transparent border-0 py-0"}>
                                <Link to="/*">instagram</Link>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
                <div className="container">
                    <div className="under-footer">
                        <ListGroup as={"ul"} className="con-info">
                            <ListGroup.Item as={"li"} className={"bg-transparent border-0 p-0"}>
                                <p>
                                    <em className="fa fa-copyright"></em> 2023 Mokhasangham. All Right Reserved.
                                    Design and Developed by <Link to={""} onClick={() => window.location = "https://aimcrafters.com"} style={{textTransform: "none"}}>AimCrafters Software Pvt. Ltd.</Link>
                                </p>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;