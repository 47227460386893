import React, { useState } from "react";

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Header from "../Common/Header";
import SubBanner from "../Common/SubBanner";
import {Button, Col, Row} from "react-bootstrap";
import $ from "jquery";
import Footer from "../Common/Footer";

const unsplashLink = (id, width, height) =>
    `/assets/images/temple_images/${id}`;

const unsplashPhotos = [
    { id: "DSC_3957.jpg", width: 300, height: 200 },
    { id: "DSC_3964.jpg", width: 300, height: 200 },
    { id: "DSC_3970.jpg", width: 300, height: 200 },
    { id: "DSC_3978.jpg", width: 300, height: 200 },
    { id: "DSC_3988.jpg", width: 300, height: 200 },
    { id: "DSC_3989.jpg", width: 300, height: 200 },
    { id: "DSC_4006.jpg", width: 300, height: 200 },
    { id: "DSC_4010.jpg", width: 300, height: 200 },
    { id: "DSC_4028.jpg", width: 300, height: 200 },
    { id: "DSC_4041.jpg", width: 300, height: 200 },
    { id: "DSC_4047.jpg", width: 300, height: 200 },
    { id: "DSC_4048.jpg", width: 300, height: 200 },
    { id: "DSC_4055.jpg", width: 300, height: 200 },
    { id: "DSC_4070.jpg", width: 300, height: 200 },
    { id: "DSC_4091.jpg", width: 300, height: 200 },
    { id: "DSC_4112.jpg", width: 300, height: 200 },
    { id: "DSC_4124.jpg", width: 300, height: 200 },
    { id: "DSC_4128.jpg", width: 300, height: 200 },
    { id: "DSC_4131.jpg", width: 300, height: 200 },
    { id: "DSC_4134.jpg", width: 300, height: 200 },
    { id: "DSC_4139.jpg", width: 300, height: 200 },
    { id: "DSC_4143.jpg", width: 300, height: 200 },
    { id: "DSC_4148.jpg", width: 300, height: 200 },
    { id: "DSC_4152.jpg", width: 300, height: 200 },
    { id: "DSC_4153.jpg", width: 300, height: 200 },
    { id: "DSC_4155.jpg", width: 300, height: 200 },
    { id: "DSC_4157.jpg", width: 300, height: 200 },
    { id: "DSC_4166.jpg", width: 300, height: 200 },
    { id: "DSC_4167.jpg", width: 300, height: 200 },
    { id: "DSC_4168.jpg", width: 300, height: 200 },
    { id: "DSC_4169.jpg", width: 300, height: 200 },
    { id: "DSC_4177.jpg", width: 300, height: 200 },
    { id: "DSC_4197.jpg", width: 300, height: 200 },
    { id: "DSC_4200.jpg", width: 300, height: 200 },
    { id: "DSC_4204.jpg", width: 300, height: 200 },
    { id: "DSC_4212.jpg", width: 300, height: 200 },
    { id: "DSC_4214.jpg", width: 300, height: 200 },
    { id: "DSC_4219.jpg", width: 300, height: 200 },
    { id: "DSC_4220.jpg", width: 300, height: 200 },
    { id: "DSC_4232.jpg", width: 300, height: 200 },
    { id: "DSC_4236.jpg", width: 300, height: 200 },
    { id: "DSC_4244.jpg", width: 300, height: 200 },
    { id: "DSC_4248.jpg", width: 300, height: 200 },
    { id: "DSC_4251.jpg", width: 300, height: 200 },
    { id: "DSC_4255.jpg", width: 300, height: 200 },
    { id: "DSC_4262.jpg", width: 300, height: 200 },
    { id: "DSC_4267.jpg", width: 300, height: 200 },
    { id: "DSC_4269.jpg", width: 300, height: 200 },
    { id: "DSC_4278.jpg", width: 300, height: 200 },
];

const breakpoints = [500, 500, 500, 500, 500];

const photos = unsplashPhotos.map((photo) => {
    const width = breakpoints[0];
    const height = (photo.height / photo.width) * width;

    return {
        src: unsplashLink(photo.id, width, height),
        width,
        height,
        images: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: unsplashLink(photo.id, breakpoint, height),
                width: breakpoint,
                height,
            };
        }),
    };
});


const slides = photos.map(({ src, width, height, images }) => ({
    src,
    width,
    height,
    srcSet: images.map((image) => ({
        src: image.src,
        width: image.width,
        height: image.height,
    })),
}));

const Gallery = (props) => {
    const [index, setIndex] = useState(-1);

    return (
        <>
            <Header/>
            <SubBanner bannerTitle={props.bannerTitle} bannerContent={props.bannerContent}/>

            <div className="content">
                <section className="sec-100px gallery bg-white">
                    <div className="container">
                        <PhotoAlbum photos={photos} layout="rows" targetRowHeight={150} onClick={({ index }) => setIndex(index)} />

                        <Lightbox
                            slides={slides}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                            // enable optional lightbox plugins
                            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                        />
                    </div>
                </section>
            </div>

            <Footer/>
        </>
    );
};

export default Gallery;

