import React from "react";
import {Link, NavLink} from "react-router-dom";
import {ListGroup, Button} from "react-bootstrap";
import BurgerImage from "./burger-close-white.svg";
import $ from "jquery";

class Header extends React.Component {
    render() {
        return (
            <header>
                <div className="container d-none">
                    <div className="top-bar">
                        <div className="open-time">
                            <p><em className="ion-ios-clock-outline"></em> Mokhasan opening hours: 8AM to 7PM. Open all days</p>
                        </div>
                        <div className="call">
                            <p><em className="ion-headphone"></em> 1800 123 4659</p>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="logo">
                        <Link to="/"><img src="/assets/images/logo.png" alt=""/></Link>
                        <div className="burger-menu">
                            <Button className={"py-2 px-3 m-0"} onClick={()=> {
                                $("#ownmenu").toggleClass("d-block")
                            }}>
                                <img src={BurgerImage} alt="" className={"py-2"} style={{maxWidth: 28}}/>
                            </Button>
                        </div>
                    </div>
                    <nav>
                        <ListGroup as={"ul"} id="ownmenu" className="ownmenu">
                            <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                                <NavLink to="/">Home</NavLink>
                            </ListGroup.Item>
                            <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                                <NavLink to="/events">Events</NavLink>
                            </ListGroup.Item>
                            <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                                <NavLink to="/gallery">Gallery</NavLink>
                            </ListGroup.Item>
                            <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                                <NavLink to="/about">About</NavLink>
                            </ListGroup.Item>
                            <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                                <NavLink to="/sponsors">Help</NavLink>
                            </ListGroup.Item>
                            <ListGroup.Item as={"li"} className={"bg-transparent border-0"}>
                                <NavLink to="/contact">Contact</NavLink>
                            </ListGroup.Item>
                        </ListGroup>
                    </nav>
                </div>
            </header>
        )
    }
}

export default Header;