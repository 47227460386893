import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import SubBanner from "../Common/SubBanner";
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {PostData} from "../api_key/PostData";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

class RegisterYourDaughter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            first_name: "",
            first_nameErr: "",
            last_name: "",
            last_nameErr: "",
            father_name: "",
            father_nameErr: "",
            husband_name: "",
            husband_nameErr: "",
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
            reCaptchaToken: "",
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
        this.submitDaughterForm = this.submitDaughterForm.bind(this)
        this.setTokenValue = this.setTokenValue.bind(this)

    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    setTokenValue(token) {
        this.setState({reCaptchaToken: token})
    }

    submitDaughterForm() {
        let formData = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            father_name: this.state.father_name,
            husband_name: this.state.husband_name,
            googleRecaptchaResponse: this.state.reCaptchaToken
        }

        PostData("front/daughter_registration", formData)
            .then(result => {
                if (result.status === false) {
                    if (result.message.first_name === "") {
                        this.setState({first_nameErr: ""})
                    } else {
                        this.setState({first_nameErr: result.message.first_name})
                    }
                    if (result.message.last_name === "") {
                        this.setState({last_nameErr: ""})
                    } else {
                        this.setState({last_nameErr: result.message.last_name})
                    }
                    if (result.message.father_name === "") {
                        this.setState({father_nameErr: ""})
                    } else {
                        this.setState({father_nameErr: result.message.father_name})
                    }
                    if (result.message.husband_name === "") {
                        this.setState({husband_nameErr: ""})
                    } else {
                        this.setState({husband_nameErr: result.message.husband_name})
                    }
                } else {
                    this.setState({
                        commonAlert: {
                            show: true,
                            variant: "success",
                            message: result.response
                        }
                    })
                    this.handleClose()
                }
            })
    }

    handleClose() {
        this.setState({
            first_name: "",
            first_nameErr: "",
            last_name: "",
            last_nameErr: "",
            father_name: "",
            father_nameErr: "",
            husband_name: "",
            husband_nameErr: "",
        })
    }

    changeValue = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {
        return (<>
            <Header/>
            <SubBanner bannerTitle={this.props.bannerTitle} bannerContent={this.props.bannerContent}/>

            <div className="content">
                <section className="sec-100px contact-us">
                    <div className="container">
                        <div className="contact-form">
                            <Form id="contact_form" className="contact-form" method="post">
                                <Row as={"ul"}
                                     className={"d-flex align-items-center justify-content-center flex-column"}>
                                    <Col sm={12}>
                                        <Alert show={this.state.commonAlert.show}
                                               variant={this.state.commonAlert.variant} className="rounded-lg">
                                            <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                        </Alert>
                                    </Col>
                                    <Col as={"li"} sm={7}>
                                        <label>
                                            <Form.Control type="text"
                                                          name="first_name"
                                                          id="first_name"
                                                          placeholder="FIRST NAME"
                                                          value={this.state.first_name}
                                                          onChange={this.changeValue}/>
                                            {this.state.first_nameErr &&
                                                <span className={"text-danger"}>{this.state.first_nameErr}</span>}
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={7}>
                                        <label>
                                            <Form.Control type="text"
                                                          name="last_name"
                                                          id="last_name"
                                                          placeholder="LAST NAME"
                                                          value={this.state.last_name}
                                                          onChange={this.changeValue}/>
                                            {this.state.last_nameErr &&
                                                <span className={"text-danger"}>{this.state.last_nameErr}</span>}
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={7}>
                                        <label>
                                            <Form.Control type="text"
                                                          name="father_name"
                                                          id="father_name"
                                                          placeholder="FATHER NAME"
                                                          value={this.state.father_name}
                                                          onChange={this.changeValue}/>
                                            {this.state.father_nameErr &&
                                                <span className={"text-danger"}>{this.state.father_nameErr}</span>}
                                        </label>
                                    </Col>
                                    <Col as={"li"} sm={7}>
                                        <label>
                                            <Form.Control type="text"
                                                          name="husband_name"
                                                          id="husband_name"
                                                          placeholder="HUSBAND NAME"
                                                          value={this.state.husband_name}
                                                          onChange={this.changeValue}/>
                                            {this.state.husband_nameErr &&
                                                <span className={"text-danger"}>{this.state.husband_nameErr}</span>}
                                        </label>
                                    </Col>
                                </Row>

                                <div className="text-center">
                                    <Button variant={"primary"} onClick={this.submitDaughterForm}>Submit</Button>
                                    <Button variant={"primary"} onClick={this.handleClose}
                                            className={"ml-2"}>Reset</Button>
                                </div>
                                <GoogleReCaptchaProvider
                                    reCaptchaKey="6Lcni2wkAAAAAKykRhLubTzdf1b_ew-uKwQO3Wnc"
                                    scriptProps={{
                                        async: false, // optional, default to false,
                                        defer: false, // optional, default to false
                                        appendTo: 'head', // optional, default to "head", can be "head" or "body",
                                        nonce: undefined // optional, default undefined
                                    }}
                                >
                                    <GoogleReCaptcha onVerify={this.setTokenValue}/>
                                </GoogleReCaptchaProvider>
                            </Form>
                        </div>
                    </div>
                </section>
            </div>

            <Footer/>
        </>)
    }
}

export default RegisterYourDaughter;