import React from "react"
import AdminHeader from "./AdminHeader";
import AdminSidebar from "./AdminSidebar";
import AdminFooter from "./AdminFooter";
import Dropzone from 'react-dropzone'
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {PostData} from "../api_key/PostData";

class AdminGallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
            accepted: [],
            rejected: [],
            uploadImagesModal: false,
        }
        document.title = this.props.pageTitle
        this.handleClose = this.handleClose.bind(this)
        this.saveAllImages = this.saveAllImages.bind(this)
    }

    componentDidMount() {
        const cookieDataReplace = document.cookie.split('; ').find((row) => row.startsWith('mokhasanLogin='))
        let sessionData = JSON.parse(cookieDataReplace.replace('mokhasanLogin=', ''));
        this.setState({
            loginUsername: sessionData[0].full_name,
            loginUserUUID: sessionData[0].uuid,
            loginUserToken: sessionData[0].token
        });
    }

    onDrop = (accepted, rejected) => {
        this.setState({
            accepted: accepted,
            rejected: rejected,
            uploadFileContent: accepted[0],
        });
    }

    saveAllImages() {
        PostData("/imageGallery/add_gallery", {file: this.state.accepted}, this.state.loginUserToken)
            .then(result => {
                console.log(result)
                if (result.status === true) {

                } else if (result.status === 401) {
                    this.setState({
                        commonAlert: {
                            show: true,
                            variant: "danger",
                            message: result.messages.message
                        }
                    })
                }
            })
    }

    handleClose() {
        this.setState({
            uploadImagesModal: false,
            commonAlert: {
                show: false,
                variant: "",
                message: ""
            },
        })
    }

    render() {
        return (<>
            <AdminSidebar/>

            <div className="content">
                <div>
                    <AdminHeader/>

                    <div className="container-fluid pt-4 px-4">
                        <div className="d-flex justify-content-end mb-3 ml-2">
                            <Button variant={"primary"} onClick={() => {
                                this.setState({
                                    uploadImagesModal: true,
                                })
                            }}>Add New Images</Button>
                        </div>
                        <Modal show={this.state.uploadImagesModal} onHide={this.handleClose} backdrop={"static"} centered keyboard={false}>
                            <Modal.Header closeButton className={"border-light"}>
                                <Modal.Title className={"text-dark"}>Add New Images</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Alert show={this.state.commonAlert.show} variant={this.state.commonAlert.variant} className="rounded-lg">
                                    <h6 className="m-0 font-weight-bold">{this.state.commonAlert.message}</h6>
                                </Alert>
                                <Form className={"upload-images-form"}>
                                    <Dropzone
                                        onDrop={this.onDrop}
                                        multiple={true}
                                    >
                                        {({getRootProps, getInputProps, isDragActive}) => (
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} name="fileUpload" id="fileUpload"/>
                                                {isDragActive ? "Drop here!" : 'Click me or drag a file to upload!'}
                                            </div>
                                        )}
                                    </Dropzone>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer className={"border-light"}>
                                <Button variant={"primary"} onClick={this.handleClose} className={"ml-2"}>Close</Button>
                                <Button variant={"success"} onClick={this.saveAllImages} className={"ml-2"}>Save Changes</Button>
                            </Modal.Footer>
                        </Modal>

                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <img className="img-responsive w-100" src="/assets/images/temple_images/DSC_3957.jpg" alt=""
                                         style={{height: 160, objectFit: "cover"}}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <img className="img-responsive w-100" src="/assets/images/temple_images/DSC_3964.jpg" alt=""
                                         style={{height: 160, objectFit: "cover"}}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <img className="img-responsive w-100" src="/assets/images/temple_images/DSC_3970.jpg" alt=""
                                         style={{height: 160, objectFit: "cover"}}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <img className="img-responsive w-100" src="/assets/images/temple_images/DSC_4236.jpg" alt=""
                                         style={{height: 160, objectFit: "cover"}}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <img className="img-responsive w-100" src="/assets/images/temple_images/DSC_4244.jpg" alt=""
                                         style={{height: 160, objectFit: "cover"}}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <img className="img-responsive w-100" src="/assets/images/temple_images/DSC_4248.jpg" alt=""
                                         style={{height: 160, objectFit: "cover"}}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <img className="img-responsive w-100" src="/assets/images/temple_images/DSC_4251.jpg" alt=""
                                         style={{height: 160, objectFit: "cover"}}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <img className="img-responsive w-100" src="/assets/images/temple_images/DSC_4255.jpg" alt=""
                                         style={{height: 160, objectFit: "cover"}}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <img className="img-responsive w-100" src="/assets/images/temple_images/DSC_4262.jpg" alt=""
                                         style={{height: 160, objectFit: "cover"}}/>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <div className="shadow rounded d-flex align-items-center justify-content-between p-4">
                                    <img className="img-responsive w-100" src="/assets/images/temple_images/DSC_4267.jpg" alt=""
                                         style={{height: 160, objectFit: "cover"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AdminFooter/>
            </div>
        </>);
    }
}

export default AdminGallery;