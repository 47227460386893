import React from "react";
import {Outlet} from "react-router-dom";
import "./bootstrap.min.css"
import "./style.css"
import "font-awesome/css/font-awesome.min.css"

class AdminPages extends React.Component {
    constructor(props) {
        super(props);
        document.querySelector("body").classList.add("adminCss")
    }

    render() {
        return (<>
                <Outlet/>
            </>
        );
    }
}

export default AdminPages;