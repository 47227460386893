import React from "react";
import {GoogleMap, useJsApiLoader, Marker} from "@react-google-maps/api";

function MokhasanMap() {
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: "AIzaSyAwwHUMvKbLgt9JG-mg6EtQfPK0ZQ5l9f8"
    })

    const containerStyle = {width: '100%', height: '100%', minHeight: '250px'};

    let center = {lat: 23.3181734, lng: 72.5334548}

    return (
        isLoaded ?
            <GoogleMap
                center={center}
                zoom={15}
                mapContainerStyle={containerStyle}
            >
                <Marker
                    key={center.lat}
                    // onClick={() => handleActiveMarker(keyValue2)}
                    position={center}
                />
            </GoogleMap> :
            "Address Not Found"
    )
}

export default MokhasanMap;